@media all and (min-width: 480px) {
  .NewIdea form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.NewIdea h2 {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
