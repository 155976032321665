@media all and (min-width: 480px) {
  .Ideas form {
    margin: 0 auto;
    max-width: 480px;
  }
}

.Ideas form div.plans {
  padding-top: 15px;
}

.Ideas .plans a.winner {
	border-color: #ccc;
	background-color: #d9ead3;
}

.Ideas .plans a.winner:hover {
	background-color: #c7e0be;
}

.Ideas .plans h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Ideas .plans p {
  color: #666;
}
